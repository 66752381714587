import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'

import 'alpinejs'
import 'tailwindcss/tailwind.css'
import 'typeface-inter'
import "lightgallery.js/dist/css/lightgallery.min.css"

require.context('../images', true)
import 'controllers'
import '../css/application.scss'


Rails.start()
ActiveStorage.start()
Turbolinks.start()


document.addEventListener("turbolinks:load", function() {
    
    //add smooth scrolling when clicking any anchor link
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
    //<a href="#someOtherElementID"> Go to Other Element Smoothly </a>

    document.getElementById("mobileNavActivate").onclick = function toggleMenu() {
        const navToggle = document.getElementsByClassName("toggle");
        for (let i = 0; i < navToggle.length; i++) {
          navToggle.item(i).classList.toggle("hidden");
        }
    };





})
