import { Controller } from 'stimulus'

import Swiper, { Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Navigation])

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      autoplay: true,
      speed: 800,
      loop: true,
      spaceBetween: 15,
      navigation: {
        nextEl: '.service-next',
        prevEl: '.service-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 3,
        },

        1280: {
          slidesPerView: 4,
        },

        1800: {
          slidesPerView: 5,
        },
      },
    })
  }
}
