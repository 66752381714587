import { Controller } from 'stimulus'

import Swiper, { Autoplay, EffectFade, Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, EffectFade, Navigation])

export default class extends Controller {
  static targets = ['nextButton', 'prevButton']

  connect() {
    new Swiper(this.element, {
      virtualTranslate: false,

      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },

      speed: 800,
      slidersPerView: 1,
      loop: true,
      effect: 'fade',
      fadeEffect: { crossFade: true },

      navigation: {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget,
      },
    })
  }
}
